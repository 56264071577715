
import { LanguageKey, Widget, WidgetExtension } from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MultiLanguageInput extends Vue {
  @Prop({
    type: Object,
  })
  public base: Widget | WidgetExtension; // base value where the property is stored on

  @Prop({
    type: Array,
    required: true,
  })
  public valuePath: string[]; // path to the property on the base value

  @Prop({
    type: String,
    default: '',
  })
  public id;

  @Prop({
    type: String,
    default: 'Aa',
  })
  public placeholder;

  @Prop({
    type: String,
    default: '',
  })
  public label: string;

  @Prop({
    type: String,
    default: 'required',
  })
  public rules;

  @Prop({
    type: Boolean,
    default: false,
  })
  public isTextarea;

  get languages() {
    const i18n = this.base.i18n;
    return Object.keys(i18n.translations) as LanguageKey[];
  }

  get composedLabel() {
    return this.label + (this.rules.includes('required') ? '*' : '');
  }

  setValue(value: string, language: LanguageKey) {
    const setValue = (base: object | object[], path: string[], value: string) => {
      if (path.length === 1) {
        this.$set(base, path[0], value);
        return;
      }
      if (!base[path[0]]) {
        this.$set(base, path[0], this.isArrayIndex(path[1]) ? [] : {});
      }
      setValue(base[path[0]], path.slice(1), value);
    };
    if (language === 'nl') {
      setValue(this.base, this.valuePath, value);
    }

    setValue(this.base.i18n.translations[language], this.valuePath, value);
  }

  getValue(language: LanguageKey) {
    const getValue = (base: object | object[], path: string[]) => {
      if (path.length === 1) {
        if (!base[path[0]]) {
          this.$set(base, path[0], '');
        }
        return base[path[0]];
      }
      if (!base[path[0]]) {
        this.$set(base, path[0], this.isArrayIndex(path[1]) ? [] : {});
      }
      return getValue(base[path[0]], path.slice(1));
    };

    return getValue(this.base.i18n.translations[language], this.valuePath);
  }

  isArrayIndex(key: string | number) {
    return (key || key === 0) && Number.isInteger(parseInt(String(key)));
  }
}
