
import { Component, Vue } from 'vue-property-decorator';
import ContactForm from '@/components/contacts/ContactForm.vue';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { Contact } from '@govflanders/mbp-admin-panel-shared';
import { getContactTemplate } from '@/utility/contacts';
import ValidationErrorNotification from '@/components/shared/ValidationErrorNotification.vue';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';

@Component({
  components: {
    Anchor,
    ContactForm,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    ValidationErrorNotification,
  },
})
export default class CertificateAdd extends mixins(PendingChangesGuard) {
  value: Contact | null = null;
  initialValue: Contact | null = null;

  private created() {
    this.initValue();
    this.initialValue = cloneDeep(getContactTemplate());
  }

  private initValue() {
    this.value = cloneDeep(getContactTemplate());
  }

  public revert() {
    this.initValue();
  }

  public save() {
    this.$store
      .dispatch('contact/create', this.value)
      .then(createdValue => {
        this.initialValue = createdValue;
        this.value = createdValue;
        this.$toast.success(this.$t('contacts.create.created'));
        this.$router.push({ name: 'contacts.edit', params: { id: createdValue.key } });
      })
      .catch(e => {
        console.error(e);
        this.$toast.error(this.$t('contacts.create.error'));
      });
  }
}
